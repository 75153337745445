import {ApiService} from "./api.service";

export class DeclarationService extends ApiService {

    constructor() {
        super();
    }

    listAll(filters, params, show_loader) {
        if (filters && filters.dropdownSearch) {
            if (this.requestToken) {
                this.requestToken.cancel();
            }
            this.requestToken = this.getCancelSource();
        }
        if (typeof params === 'undefined') {
            params = {};
        }

        if (typeof params.rows === 'undefined') {
            params.rows = filters && filters.dropdownSearch ? 10 : 50;
        }

        return this.trigger('declaration/list', {
            'first': params.offset ? params.offset : 0,
            'rows': params.rows,
            'sortField': params.sortBy ? params.sortBy : 'created_at',
            'sortOrder': params.sortDir ? params.sortDir : 1,
            'filters': filters ? filters : {},
            'requestType': params && params.requestType ? params.requestType : ''
        }, show_loader).then((response) => {
            if (response) {
                if (response.data.success && parseInt(response.data.record_count) > 0) {
                    return {
                        'totalRecordCount': response.data.total_records,
                        'stage': filters && filters.status ? filters.status : '',
                        'list': this.parse_object_data(response.data.records)
                    };
                } else if (response.data.count_by_status) {
                    return response.data.count_by_status;
                } else {
                    return {
                        'totalRecordCount': 0,
                        'stage': filters && filters.status ? filters.status : '',
                        'list': []
                    };
                }
            } else {
                return false;
            }
        })
    }

    save(data) {
        this.declarationId = 0;
        return this.trigger('declaration/save', data, true).then(response => {
            if (response.data.success) {
                this.$store.commit('user/setCurrentUserStatsDataChanged', true);
                this.declarationId = response.data.uid;
                return response.data;
            } else {
                this.$toast.error(response.data.message)
                return false;
            }
        });
    }

    saveParticipation(data) {
        return this.trigger('declaration/save_participation', data, true).then(response => {
            if (response.data.success) {
                return response.data;
            } else {
                this.$api.showToast(response.data.message)
                return false;
            }
        });
    }

    get_elster_message(declarationUid, elsterMessageId) {
        return this.trigger('declaration/get_elster_message', {
            declarationUid,
            elsterMessageId
        }).then((response) => {
            if (response.data.success) {
                return response.data.elsterMessage;
            } else {
                this.showToast(response.data.message, 'error');
                return false;
            }
        })
    }

    validateElsterMessage(data) {
        return this.trigger('declaration/validate_elster_message', data, true);
    }

    sendElsterMessage(data) {
        return this.trigger('declaration/send_elster_message', data, true);
    }

    getFileContentFromS3(data) {
        return this.trigger('declaration/get_file_content_from_s3', data, true);
    }

    start_mass_update_job(data) {
        return this.trigger('declaration/start_mass_update_job', data).then((response) => {
            if (response.data.newNotificationCount) {
                this.$store.commit('user/setNewNotificationCount', response.data.newNotificationCount);
            }
            return response;
        })
    }

    updateStage(data) {
        return this.trigger('declaration_workflow/update_stage', data, true).then(response => {
            if (response.data.success) {
                return true;
            } else {
                this.showToast(response.data.message, 'error')
                return false;
            }
        });
    }

    saveAreaOfTheLandData(data) {
        return this.trigger('declaration/save_area_of_the_land_data', data, true).then(response => {
            if (response.data.success) {
                return true;
            } else {
                this.showToast(response.data.message, 'error')
                return false;
            }
        });
    }

    saveAssessmentRate(data) {
        return this.trigger('declaration/save_assessment_rate', data, true).then(response => {
            if (response.data.success) {
                return true;
            } else {
                this.showToast(response.data.message, 'error');
                return false;
            }
        });
    }

    saveAppealType(data) {
        return this.trigger('declaration/save_appeal_type', data, true).then(response => {
            if (response.data.success) {
                return true;
            }

            this.showToast(response.data.message, 'error')
            return false;
        });
    }

    async dragDropCompleted() {
        const newStatus = this.$store.getters['declaration/getNewStatus'];
        const oldStatus = this.$store.getters['declaration/getOldStatus'];
        const addedElement = this.$store.getters['declaration/getAddedElement'];
        const removedElement = this.$store.getters['declaration/getRemovedElement'];

        var returnValue = false;
        if (newStatus !== '' && oldStatus !== '' && newStatus !== oldStatus && typeof addedElement.id !== 'undefined' && addedElement.id === removedElement.id) {
            returnValue = await this.updateStage({
                'prim_uid': addedElement.id,
                'stage': newStatus
            });
        }

        return returnValue;
    }

    archive(data) {
        return this.trigger('declaration/archive', data, true).then(response => {
            if (response.data.success) {
                return true;
            } else {
                this.showToast(response.data.message, 'error')
                return false;
            }
        });
    }

    unarchive(data) {
        return this.trigger('declaration/unarchive', data, true).then(response => {
            if (response.data.success) {
                return true;
            } else {
                this.showToast(response.data.message, 'error')
                return false;
            }
        });
    }

    get(uid) {
        return this.trigger('declaration/get', {
            uid: uid
        }, true).then((response) => {
            if (response.data.success) {
                if (response.data.declarationData.id) {
                    response.data.declarationData.id = parseInt(response.data.declarationData.id);
                }

                if (response.data.declarationData.submissions) {
                    response.data.declarationData.submissions = Object.values(response.data.declarationData.submissions);
                }

                return response.data.declarationData;
            } else {
                this.$toast.error(response.data.message);
                return [];
            }
        })
    }

    checkCommunity(uid) {
        return this.trigger('declaration/check_community', {
            uid
        }).then((response) => {
            if (response.data.success) {
                return {'error': false, 'data': response.data.taxCalculation};
            } else {

                this.showToast(response.data.message, 'error');
                return {'error': true, 'data': response.data.errorKey || response.data.error_key};
            }
        })

    }

    get_communities({uid, community, communityUid}) {

        return this.trigger('declaration/get_communities', {
            uid, community, communityUid
        }).then((response) => {
            if (response.data.success) {
                return {'error': false, 'data': response.data};
            } else {

                this.showToast(response.data.message, 'error');
                return {'error': true, 'data': response.data.errorKey || response.data.error_key};
            }
        })

    }

    getTaxCalculation(uid, format, community, save_calculation_community = false) {
        if (typeof format === 'undefined') {
            format = 'html';
        }

        if (format === 'pdf') {
            this.trigger('declaration/get_tax_calculation', {
                uid,
                format,
                community,

            }, true, '', 'blob').then((response) => {
                this.trigger_download(response);
            });
        } else {
            return this.trigger('declaration/get_tax_calculation', {
                uid,
                format,
                community,
                save_calculation_community: save_calculation_community ? 1 : 0
            }).then((response) => {
                if (response.data.success) {
                    if (format === 'view-pdf') {
                        return response;
                    } else {
                        return {
                            'error': false,
                            'data': response.data.taxCalculation,
                            'error_key': null
                        };
                    }
                } else {
                    this.showToast(response.data.message, 'error');
                    return {
                        'error': true,
                        'data' : null,
                        'error_key': response.data.error_key || 'unknownError',
                         'values' : response.data.values || ''
                    };
                }
            })
        }
    }

    getTaxDocument(
        uid,
        documentType,
        regenerate = false,
        freezedPropertyUid= 0,
        taxDocumentUid = 0,
        elsterSubmissionReceivedDate = ''
    ) {
        return this.trigger('declaration/get_tax_document', {
            uid,
            documentType,
            regenerate,
            freezedPropertyUid,
            taxDocumentUid,
            elsterSubmissionReceivedDate
        }).then((response) => {
            if (response.data.success) {
                return response.data.document;
            } else if (documentType === 'tax_assessment') {
                return {
                    'document_type': documentType,
                    'documentContent': ''
                };
            } else if(response.data.info) {
                this.showToast(response.data.info, 'info');
                return response;
            } else {
                this.showToast(response.data.message, 'error');
                return response;
            }
        })
    }

    getPropertyValueCalculation(uid, community, format, showLoader, save_calculation_community, considerExemption = false,refreshValue = false) {
        if (typeof showLoader === 'undefined') {
            showLoader = false;
        }

        if (format === 'pdf') {
            this.trigger('declaration/property_value_calculation', {
                uid,
                format,
                community,
                considerExemption,
                refreshValue
            }, showLoader, '', 'blob').then((response) => {
                this.trigger_download(response);
            });
        } else {
            return this.trigger('declaration/property_value_calculation', {
                uid,
                community,
                format,
                save_calculation_community: save_calculation_community ? 1 : 0,
                considerExemption,
                refreshValue
            }, showLoader).then((response) => {
                if (response.data.success) {
                    return {
                        error: false,
                        data: response.data
                    };
                } else {
                    this.showToast(response.data.message, 'error');
                    return {
                        error: true,
                        data: response.data.errorKey || response.data.error_key,
                        values: response.data.values || ''
                    };
                }
            });
        }
    }

    sendEmail(data) {
        return this.trigger('declaration/send_email', data, true).then(response => {
            if (response.data.success) {
                return true;
            } else {
                this.$toast.error(response.data.message)
                return false;
            }
        });
    }

    export(columns, filters, selectedRecords) {
        let column_data = [];
        columns !== null && columns.map(column => {
            column_data.push({columnOrder: column.columnOrder, field: column.field});
        })

        return this.trigger('declaration/export', {
            export_filters: filters,
            columns: column_data,
            selectedRecords
        }, true, '', 'blob').then((response) => {
            if (response.headers['download_later']) {
                this.showToast(this.$i18n.t('general.export_in_background'))
            } else {
                this.trigger_download(response);
            }
        });
    }

    elsterValidate(data, show_loader) {
        if (typeof show_loader === 'undefined') {
            show_loader = true;
        }

        return this.trigger('declaration/validate', data, show_loader).then(response => {
            return response.data;
        });
    }

    elsterDownloadPdf(data) {
        return this.trigger('declaration/download_pdf', data, true).then(response => {
            return response.data;
        });
    }

    downloadCSV(columns, invoiceId) {
        let column_data = [];
        columns !== null && columns.map(column => {
            column_data.push({columnOrder: column.columnOrder, field: column.field});
        })
        return this.trigger('declaration/downloadCSV', {
            columns: column_data, invoiceId
        }, true, '', 'blob')
            .then((response) => {
                this.trigger_download(response);
            });
    }

    activateAllPendingElsterTransmission() {
        return this.trigger('declaration/activate_pending_elster', {}, true);
    }

    approversList(declarationUid) {
        return this.trigger('declaration/approvers_list', {
            'declarationUid': declarationUid
        }, true);
    }
    regenerateCalculation(declarationUid){
        return this.trigger('declaration/regenerate_calculation', {
            'declarationUid': declarationUid
        }, true);
    }
}
